import {HttpInterceptorFn} from '@angular/common/http';
import {inject} from '@angular/core';
import {AuthService} from './modules/shared/services/auth.service';
import {environment} from '../environments/environment';
import {ParamEnum} from './modules/shared/enums/param.enum';

export const httpRequestInterceptor: HttpInterceptorFn = (request, next) => {
  const apiUrls = [environment.ibricksApiV2, environment.ibricksApiV3, environment.imageUpload];

  if (apiUrls.some(i => request.url.includes(i))) {
    const anonymousUse = request?.params?.get(ParamEnum.AnonymousUse) === '1';
    const org = request?.params?.get(ParamEnum.Org) || '';

    request = request.clone({
      withCredentials: anonymousUse ? false : environment.production,
      params:
        org.length || request.url.endsWith('/profile')
          ? request.params
          : request.params.set(ParamEnum.Org, inject(AuthService).org)
    });

    if (!environment.production && environment.username && environment.password) {
      request = request.clone({
        headers: request.headers.append(
          'Authorization',
          'Basic ' + btoa(`${environment.username}:${environment.password}`)
        )
      });
    }
  }

  return next(request);
};
